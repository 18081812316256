
import { defineComponent } from "vue";

export default defineComponent({
	name: "NCheckbox",

	props: {
		modelValue: Boolean,
		id: String
	},

	emits: ["update:modelValue"],

	setup(props, { emit, slots }) {
		const hasSlot = (name: string | number) => !!slots[name];
		const onClick = (e: any) => emit("update:modelValue", e.target.checked);

		const onEnter = (e: any) => {
			e.target.checked = !e.target.checked;
			emit("update:modelValue", e.target.checked);
		};

		return {
			onClick,
			onEnter,
			hasSlot
		};
	}
});
