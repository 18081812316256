<template>
	<input :value="modelValue" class="block sm:text-sm rounded-xl" @keyup.enter="enter" @input="onChanged" />
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
	name: "NInput",

	props: {
		modelValue: String
	},

	emits: ["update:modelValue", "enter"],

	setup(props, { emit }) {
		function onChanged(e) {
			emit("update:modelValue", e.currentTarget.value);
		}

		function enter(e) {
			console.log("enter");
			emit("enter", e);
		}

		return {
			onChanged,
			enter
		};
	}
});
</script>
