<template>
	<div class="orb" :class="{ stopped }">
		<div :class="big ? 'big' : 'talk'">
			<div class="wrapper">
				<ul class="ball">
					<li class="ring"></li>
				</ul>
				<ul class="ball ball2">
					<li class="ring"></li>
				</ul>
				<ul class="ball ball3">
					<li class="ring"></li>
				</ul>
				<ul class="ball ball4">
					<li class="ring"></li>
				</ul>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: ["stopped", "big"]
};
</script>
<style lang="scss">
.orb {
	margin-bottom: 120px;
	margin-top: -120px;
	&.stopped {
		.ball {
			animation-play-state: paused;
		}
	}

	@keyframes roundandround {
		to {
			transform: rotateX(360deg) rotateY(360deg);
		}
	}

	@keyframes show {
		to {
			opacity: 1;
		}
	}

	* {
		--tw-ring-shadow: none !important;
		box-shadow: none !important;
		--tw-ring-offset-shadow: none !important;
	}

	.scene {
		width: 40px;
		height: 40px;
		margin: 2% auto;
		perspective: 1000px;
	}
	.big {
		width: 100px;
		height: 100px;
		margin: 2% auto;
		perspective: 1000px;
	}
	.talk {
		width: 25px;
		height: 25px;
		margin: 2% auto;
		perspective: 1000px;
	}
	.wrapper {
		width: 100%;
		height: 100%;
		transform: rotateX(45deg) rotateY(45deg);
		transform-style: preserve-3d;
	}
	.ball {
		position: absolute;
		top: 100px;
		left: 0;
		width: 100%;
		height: 100%;
		margin: 0 auto;
		transform-style: preserve-3d;
		animation: roundandround 7.5s 0s infinite linear;
	}
	.ball2 {
		animation: roundandround 5s 0s infinite linear;
		opacity: 0.6;
	}
	.ball3 {
		animation: roundandround 6s 0s infinite linear;
		opacity: 0.5;
	}
	.ball4 {
		animation: roundandround 3.8s 0s infinite linear;
		opacity: 0.7;
	}
	.ball .ring {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 3px;
		border-style: solid;
		border-radius: 50%;
		opacity: 0.8;
		color: white;
	}

	// Mobile size
	@media (max-width: 639px) {
		.big {
			width: 60px;
			height: 60px;
		}
	}
}
</style>
