
import { defineComponent } from "vue";

export default defineComponent({
	name: "NTextarea",

	props: {
		modelValue: String,
		inputRef: String
	},

	emits: ["update:modelValue", "enter"],

	setup(props, { emit }) {
		const onChanged = (e: any) => emit("update:modelValue", e.currentTarget.value);

		return {
			onChanged
		};
	},
	mounted() {
		if (this.$refs?.feedbackMessage && typeof this.$refs?.feedbackMessage === "object") {
			// @ts-ignore
			this.$refs?.feedbackMessage.focus();
		}
	}
});
