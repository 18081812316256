<template>
	<a class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem"><slot></slot></a>
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
	components: {},
	props: {
		test: String
	}
})
export default class Message extends Vue {
	public message: any;
}
</script>
