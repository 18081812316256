<template>
	<div class="w-full flex flex-row items-center justify-center mt-2 md:mt-1">
		<div class="title text-nBlue uppercase p-1 pr-4 font-semibold">
			<slot name="title"></slot>
		</div>
		<!-- Loop stars with props value starsCount -->
		<button v-for="(star, index) in starsCount" :key="index" :aria-pressed="ariaPressed(index + 1)" class="p-2 fill-current text-nBlue" :class="{ 'opacity-30': value < index + 1 }" @click="setValue(index + 1)"><starIcon class="w-4 h-5" /></button>
	</div>
</template>

<script>
import { defineComponent, ref } from "vue";
import { starIcon } from "@/icons";

export default defineComponent({
	name: "NStars",

	components: {
		starIcon
	},
	props: {
		modelValue: Number,
		starsCount: {
			type: Number,
			default: 5
		}
	},

	emits: ["update:modelValue"],

	setup(props, { emit }) {
		const value = ref(0); // Initial start value

		function setValue(value) {
			this.value = value;
			emit("update:modelValue", value);
		}

		return {
			value,
			setValue
		};
	},
	methods: {
		ariaPressed(index) {
			if (this.value === index) {
				return true;
			}
			if (this.value > index) {
				return "mixed";
			}
			return false;
		}
	}
});
</script>
