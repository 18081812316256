<template>
	<div class="message-wrapper max-w break-words">
		<div :class="type === 'user' ? 'user justify-end' : 'bot place-content-start'" class="flex">
			<!-- Images -->
			<img v-if="type === 'image'" class="w-4/6 ml-12" :src="message.source" />
			<!-- TODO: add tabindex="0" and a description -->
			<!-- Text -->
			<div v-else-if="type === 'text'" id="orb-holder" class="flex flex-row w-full">
				<orb :talk="true" class="talking-orb" />
				<div class="flex items-center bubble bot text-left rounded-xl bg-nBlue text-white w-3/4 p-4 m-2 ml-6">
					<!--<div class="bubble bot text-left rounded-xl bg-nBlue text-white w-3/4 p-4 m-2 ml-12" v-html="$parent.i18n(message.text)"></div>-->
					<div v-html="$parent.i18n(message.text)"></div>
					<nAudio v-if="message.speech" ref="speechPlayer" theme="light" :src="message.speech.url" @nAudioState="$emit('nAudioState', $event)" @nAudioCreated="$emit('nAudioCreated', $event)" />
				</div>
			</div>
			<!-- Options-->
			<div v-else-if="type === 'option'" style="text-align: left" class="options m-2 ml-11 w-4/5 transition duration-300 ease-out-in">
				<!-- Options title -->
				<div class="flex items-center">
					<h3 class="inline-block title mt-3 mb-3 ml-1 text-sm text-nBlue">{{ $parent.i18n(message.title) }}</h3>
					<nAudio v-if="message.titleSpeech" ref="speechPlayer" class="inline-block" :src="message.titleSpeech.url" @nAudioState="$emit('nAudioState', $event)" @nAudioCreated="$emit('nAudioCreated', $event)" />
				</div>

				<!-- Options values -->
				<div class="">
					<div v-for="(option, index) in message.options" :key="index" class="inline-block">
						<div class="flex items-center">
							<nButton class="m-1 inline-flex text-nBlue bg-white hover:bg-nBlue hover:text-white py-4 px-4" @click="$parent.ask($parent.i18n(option.value.input.text))">{{ $parent.i18n(option.label) }}</nButton>
							<nAudio v-if="option.labelSpeech" ref="speechPlayer" class="mr-2" :src="option.labelSpeech.url" @nAudioState="$emit('nAudioState', $event)" @nAudioCreated="$emit('nAudioCreated', $event)" />
						</div>
					</div>
				</div>
			</div>
			<!-- Suggestions -->
			<div v-else-if="type === 'suggestion'" class="p-2 m-2 w-4/6 ml-12">
				<h3 class="title">{{ $parent.i18n(message.title) }}</h3>
				<div class="suggestions">
					<nButton v-for="(suggestion, index) in message.suggestions" :key="index" class="inline-flex my-2 ml-12" @click="$parent.ask($parent.i18n(suggestion.value.input.text, suggestion))">{{ $parent.i18n(suggestion.label) }}</nButton>
				</div>
			</div>
			<!-- User -->
			<div v-else-if="type === 'user'" :ref="ref" style="max-width: 72%" class="bubble relative inline-flex rounded-xl rounded-tr-none bg-nBlue text-white p-4 m-2 mr-16">{{ message.input }}</div>
			<!-- Blocks -->
			<div v-else-if="type === 'blocks'" class="w-4/5 p-4 ml-7">
				<div v-for="(block, index) of message.blocks" :key="index" class="bubble relative block rounded-xl rounded-tl-none bg-white p-5 m-1 mb-3 text-left" :class="block.expanded ? 'shadow-2xl' : ''">
					<!-- <h2 class="cursor-pointer -m-5 p-5 px-15 relative" :class="block.expanded ? 'bg-nBlue text-white block rounded-tr-xl' : 'bg-white text-nBlue rounded-xl rounded-tl-none'" @click="block.expanded = !block.expanded">{{ $parent.i18n(block.title) }}<button class="transition transform absolute -right-2 -translate-y-2/4 top-1/2" :class="block.expanded ? '-rotate-90' : ''"><chatArrowIcon class="w-5 h-5 transition-paths" :class="{ inverted: block.expanded }" /></button></h2> -->
					<!-- Block title -->
					<div style="min-height: 64px" class="flex items-center -m-5 p-5 py-2 relative" :class="block.expanded ? 'bg-nBlue text-white block rounded-tr-xl' : 'bg-white text-nBlue rounded-xl rounded-tl-none'">
						<h2 class="cursor-pointer -m-5 p-5 px-15 relative" @click="block.expanded = !block.expanded">{{ $parent.i18n(block.title) }}</h2>
						<nAudio v-if="block.titleSpeech" ref="speechPlayer" :theme="block.expanded ? 'light' : ''" :src="block.titleSpeech.url" class="ml-1" @nAudioState="$emit('nAudioState', $event)" @nAudioCreated="$emit('nAudioCreated', $event)" />
						<div class="flex-1 self-stretch cursor-pointer" @click="block.expanded = !block.expanded">
							<button class="transition transform absolute -right-2 -translate-y-2/4 top-1/2" :class="block.expanded ? '-rotate-90' : ''"><chatArrowIcon class="w-5 h-5 transition-paths" :class="{ inverted: block.expanded }" /></button>
						</div>
					</div>
					<!-- Expanded content -->
					<div class="flex items-center">
						<div v-if="block.expanded" class="mt-10" v-html="$parent.i18n(block.text)"></div>
						<nAudio v-if="block.expanded && block.textSpeech" ref="speechPlayer" :src="block.textSpeech.url" class="mt-5" @nAudioState="$emit('nAudioState', $event)" @nAudioCreated="$emit('nAudioCreated', $event)" />
					</div>
				</div>
			</div>
			<!-- Unknown -->
			<div v-else>{{ $parent.i18n("Sorry, I don't know how to respond to your question...") }}</div>
		</div>
	</div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import nButton from "./nButton.vue";
import nAudio from "@/components/nAudio.vue";
import { chatArrowIcon } from "@/icons";
import orb from "./orb.vue";

@Options({
	name: "Message",
	components: {
		nButton,
		nAudio,
		chatArrowIcon,
		orb
	},
	props: {
		type: String,
		message: Object,
		ref: Number,
		expandevent: Function
	},
	emits: ["nAudioCreated", "nAudioState"]
})
export default class Message extends Vue {
	public type!: "image" | "text" | "option" | "suggestion" | "user";
	public message: any;
	public ref?: number;
	public expandevent?: any;

	private messageState: any;

	@Watch("message.blocks", { deep: true })
	private onBlockChange(newBlocks: any) {
		const messageState = JSON.parse(this.messageState);
		let index = -1;

		for (let i = 0; i < newBlocks.length; i++) {
			// We are only interested in expansion/collapse of the block
			if (newBlocks[i].expanded !== messageState.blocks[i].expanded) {
				index = i;
				break;
			}
		}

		if (typeof this.expandevent === "undefined" || index === -1) {
			// No event has been defined
			return;
		}
		// Save new state to compare with
		this.messageState = JSON.stringify(this.message);
		// Call custom event on if expanded = true, we do not need to log collapsing
		this.expandevent(this.message.blocks[index].blockId, newBlocks[index].expanded);
	}

	public created() {
		this.messageState = JSON.stringify(this.message);
	}
}
</script>

<!-- TODO: fix in tailwind -->
<style lang="scss">
.text-white {
	color: white;
}
.talking-orb {
	position: relative;
	left: 40px;
	top: 40px;
}
.user .bubble:before {
	@apply absolute inline-block;
	background-image: url(https://neuvola.com/images/eloisa/radar-3.png); // Wtf is this shit? -> HAHAHAHAHAH
	background-size: 13px 13px;
	width: 13px;
	height: 13px;
	top: -5px;
	left: -5px;
	content: "";
}
.user .bubble:after {
	@apply absolute top-0 w-screen;
	content: "";
	background: rgba(18, 87, 132, var(--tw-bg-opacity));
	height: 2px;
	right: -100vw;
}
.message-wrapper > div {
	animation: fadein 0.85s;
}
.message-wrapper {
	ul,
	ol {
		list-style: initial;
		padding-left: 1em;
	}
}
@keyframes fadein {
	0% {
		transform: translate(0, 0px) scale(0.98);
		opacity: 0.8;
	}
	50% {
		transform: translate(0, 0px) scale(1.01);
		opacity: 0.8;
	}
	100% {
		transform: translate(0, 0) scale(1);
		opacity: 1;
	}
}
</style>
