
import { reactive } from "vue";
import { Options, Vue } from "vue-class-component";
import nButton from "./components/nButton.vue";
import nDropdown from "./components/nDropdown.vue";
import nDropdownItem from "./components/nDropdownItem.vue";
import nDropdownItemGroup from "./components/nDropdownItemGroup.vue";
import nInput from "./components/nInput.vue";
import nCheckbox from "./components/nCheckbox.vue";
import nTextarea from "./components/nTextarea.vue";
import nModal from "./components/nModal.vue";
import nStars from "./components/nStars.vue";
import orb from "./components/orb.vue";
import * as config from "./config.json";
import { Watch } from "vue-property-decorator";
import Message from "./components/Message.vue";
import Hcaptcha from "@/components/Hcaptcha.vue";
import { parse } from "semver";

// login
import crypto from "crypto-js";

import axios from "axios";

import { closeIcon, chatGlobeIcon, keyboardIcon, chatArrowIcon, micIcon } from "@/icons";

const Spin = {
	show: () => {
		console.log("spinning");
	},
	hide: () => {
		console.log("stop spinning");
	}
};

@Options({
	name: "App",
	components: {
		nModal,
		Message,
		nButton,
		nInput,
		nCheckbox,
		nTextarea,
		nStars,
		nDropdown,
		nDropdownItemGroup,
		nDropdownItem,
		orb,
		"vue-hcaptcha": Hcaptcha,
		closeIcon,
		chatGlobeIcon,
		keyboardIcon,
		chatArrowIcon,
		micIcon
	}
})
export default class App extends Vue {
	// Code related to auth
	public password = "";
	public login = false;
	public loggedin = false;
	public authMessage = "";
	// End of code related to auth
	public modalCounter = 0;
	public modals: any = reactive(new Map());
	public conversation: any[] = [];
	public askRef = 0;
	public typing = false;
	public sessionId = "";
	public assistantId = "";
	public question = "";
	public currentLanguage = "";
	public config: any = {};
	public inited = false;
	public location = "neuvo";
	public sessionTimeout = 0;
	public captcha = false;
	public captchaToken = "";
	public showGlass = true;
	public showWelcome = false;
	public showLanguages = false;
	public tab: "input" | "mic" | "language" = "input";
	public hack = {
		"palvelupiste": "asiointipiste",
		"palvelupisteet": "asiointipisteet",
		"palvelupisteen": "asiointipisteen",
		"palvelupisteiden": "asiointipisteiden",
		"palvelupisteitten": "asiointipisteitten",
		"palvelupistettä": "asiointipistettä",
		"palvelupisteitä": "asiointipisteitä",
		"palvelupisteessä": "asiointipisteessä",
		"palvelupisteissä": "asiointipisteissä",
		"palvelupisteestä": "asiointipisteestä",
		"palvelupisteistä": "asiointipisteistä",
		"palvelupisteeseen": "asiointipisteeseen",
		"palvelupisteisiin": "asiointipisteisiin",
		"palvelupisteellä": "asiointipisteellä",
		"palvelupisteillä": "asiointipisteillä",
		"palvelupisteeltä": "asiointipisteeltä",
		"palvelupisteiltä": "asiointipisteiltä",
		"palvelupisteelle": "asiointipisteelle",
		"palvelupisteille": "asiointipisteille",
		"palvelupisteenä": "asiointipisteenä",
		"palvelupisteinä": "asiointipisteinä",
		"palvelupisteeksi": "asiointipisteeksi",
		"palvelupisteiksi": "asiointipisteiksi",
		"palvelupisteettä": "asiointipisteettä",
		"palvelupisteittä": "asiointipisteittä",
		"palvelupistein": "asiointipistein",
		"staden Espoo": "staden Esbo",
		"Espoo-invånare": "Esbo-invånare",
		"Espoo-tjänster": "Esbo-tjänster",
		"perustulotukea": "perustoimeentulotukea",
		"perustulotukia": "perustoimeentulotukea",
		"lisä- ja ennaltaehkäisevää tulotukia": "täydentävää ja ehkäisevää toimeentulotukea",
		"lisä- tai ennaltaehkäisevän tulotuen": "täydentävän ja ehkäisevän toimeentulotuen",
		"kasvonaamareiden": "kasvomaskien",
		"kasvonaamijoista": "kasvomaskeista",
		"kasvonaamareista": "kasvomaskeista",
		"kasvonaamareihin": "kasvomaskeihin",
		"kasvonaamareilla": "kasvomaskeilla",
		"kasvonaamareilta": "kasvomaskeilta",
		"kasvonaamareille": "kasvomaskeille",
		"kasvonaamareiksi": "kasvomaskeiksi",
		"kasvonaamareitta": "kasvomaskeitta",
		"kasvonaamareina": "kasvomaskeina",
		"kasvonaamioita": "kasvomaskeja",
		"kasvonaamareita": "kasvomaskeja",
		"kasvonaamioissa": "kasvomaskeissa",
		"kasvonaamarien": "kasvomaskien",
		"kasvonaamiossa": "kasvomaskissa",
		"kasvonaamiolta": "kasvomaskilta",
		"kasvonaamiolla": "kasvomaskilla",
		"kasvonaamiotta": "kasvomaskitta",
		"kasvonaamiolle": "kasvomaskille",
		"kasvonaamioksi": "kasvomaskiksi",
		"kasvonaamiosta": "kasvomaskista",
		"kasvonaamioon": "kasvomaskiin",
		"kasvonaamioin": "kasvomaskein",
		"kasvonaamarit": "kasvomaskit",
		"kasvonaamarin": "kasvomaskin",
		"kasvonaamiona": "kasvomaskina",
		"kasvonaamiota": "kasvomaskia",
		"kasvonaamion": "kasvomaskin",
		"kasvonaamio": "kasvomaski"
	};
	public help = {
		language: true
	};
	public languages = [
		{
			code: "en",
			name: "English"
		}
	];
	public topLanguages = [
		{
			code: "en",
			name: "English"
		}
	];
	public languagesWithoutTopLanguages = [
		{
			code: "en",
			name: "English"
		}
	];
	public startBtns = {};
	public startBtn = "";
	public API = process.env.VUE_APP_CHAT_API;
	public version = process.env.VUE_APP_VERSION;
	public semver = parse(process.env.VUE_APP_VERSION);
	// private lastScrollIndex = -2;
	// private scrollBlock = false;
	public scrolledToBottom = true;
	public refreshing = false;
	public registration: any = undefined;
	// @ts-ignore
	public SpeechRecognition: any = window.SpeechRecognition || window.webkitSpeechRecognition || false;
	public dictation: any = false;
	public dictating = false;
	public speechAudios: any = [];

	private regexHack: any = [];
	private translationCache: any = {};
	private censors: Array<{ regex: RegExp; action: string }> = [];

	private feedbackErrorNoRating = false;
	private feedbackUIVisible = false;
	private feedback = {
		feedback: "",
		rating: 0,
		sessionId: false
	};

	public async created() {
		console.log("Looking up config based on the hostname");

		axios.defaults.baseURL = this.API;
		axios.defaults.headers.get["Accept"] = "application/json";
		axios.defaults.headers.post["Accept"] = "application/json";

		await this.loadConfig();

		const count = 0;
		// console.log(count++);
		const self = this;
		// @ts-ignore
		window.lastHeartBeat = true;
		// console.log(count++);
		document.addEventListener("swUpdated", this.showRefreshUI, { once: true });
		// console.log(count++);
		if (typeof navigator.serviceWorker === "object") {
			try {
				navigator.serviceWorker.addEventListener("controllerchange", () => {
					if (this.refreshing) {
						return;
					}
					this.refreshing = true;
					window.location.assign(window.location.href);
				});
			} catch (e) {
				console.error(e);
			}
		}
		// console.log(count++);
		config.censors.forEach((c: any) => {
			this.censors.push({
				regex: new RegExp(c.regex, "ig"),
				action: c.action
			});
		});
		// console.log(count++);
		for (const [from, to] of Object.entries(this.hack)) {
			const regex = new RegExp(from.replace(/[|\\{}()[\]^$+*?.]/g, "\\$&").replace(/-/g, "\\u002d"), "gi");
			this.regexHack.push([regex, to]);
		}
		// console.log(count++);
		// At the lowest priority, we configure the default language set in the config
		this.currentLanguage = this.config.languages.default;
		// console.log(count++);
		// We try to autodetect the language
		await this.getLanguages();

		try {
			const langCodes = this.languages.map(lang => lang.code); // Get the language codes for each supported language
			// First we test the default selected navigator.language
			if (langCodes.includes(navigator.language)) {
				this.currentLanguage = navigator.language;
				console.log({ cur: this.currentLanguage });
			} else if (Array.isArray(navigator.languages)) {
				// If there was no match, we try the first match from navigator.languages
				for (const lang of navigator.languages) {
					// First we try with the full match
					const navLang = lang.replace("_", "-"); // Make sure we use the standard format
					const navLangLocale = navLang.replace(/-.+/, ""); // Remove country
					// console.log({ navLang, navLangLocale });
					if (langCodes.includes(navLang)) {
						// Is the full language in the list?
						this.currentLanguage = navLang;
						break;
					} else if (langCodes.includes(navLangLocale)) {
						// We don't have an exact match, but do we have a non geographical match?
						this.currentLanguage = navLangLocale;
						break;
					}
				}
				// console.log({ match: this.currentLanguage });
			}
		} catch (e) {
			console.error("Failed to handle lang autodetection", e);
		}
		// console.log(count++);
		// If the language is defined as the URL param, this takes the lead
		if (typeof this.$route.query.language === "string") {
			this.currentLanguage = this.$route.query.language;
		}

		if (typeof this.config === "object" && typeof this.config.assistantId === "string") {
			this.assistantId = this.config.assistantId;
		}
		document.documentElement.lang = this.currentLanguage;
		// console.log(count++);
		let dynamicManifest = {
			name: "Neuvo",
			short_name: "Neuvo",
			icons: [
				{
					src: `${window.location.origin}/img/icons/icon-72x72.png`,
					sizes: "72x72",
					type: "image/png"
				},
				{
					src: `${window.location.origin}/img/icons/icon-96x96.png`,
					sizes: "96x96",
					type: "image/png"
				},
				{
					src: `${window.location.origin}/img/icons/icon-128x128.png`,
					sizes: "128x128",
					type: "image/png"
				},
				{
					src: `${window.location.origin}/img/icons/icon-144x144.png`,
					sizes: "144x144",
					type: "image/png"
				},
				{
					src: `${window.location.origin}/img/icons/icon-152x152.png`,
					sizes: "152x152",
					type: "image/png"
				},
				{
					src: `${window.location.origin}/img/icons/icon-192x192.png`,
					sizes: "192x192",
					type: "image/png"
				},
				{
					src: `${window.location.origin}/img/icons/icon-384x384.png`,
					sizes: "384x384",
					type: "image/png"
				},
				{
					src: `${window.location.origin}/img/icons/icon-512x512.png`,
					sizes: "512x512",
					type: "image/png"
				}
			],
			display: "standalone",
			background_color: "#e6eaef",
			theme_color: "#25638A",
			start_url: window.location.href
		};
		if (typeof this.config === "object" && typeof this.config.manifest === "object") {
			dynamicManifest = { ...dynamicManifest, ...this.config.manifest };
			if (typeof this.config.manifest.name === "string") {
				document.title = this.config.manifest.name;
			}
		}
		// console.log(count++);
		const stringManifest = JSON.stringify(dynamicManifest);
		const blob = new Blob([stringManifest], { type: "application/json" });
		const manifestURL = URL.createObjectURL(blob);
		document.querySelector("#manifest")?.setAttribute("href", manifestURL);
		// console.log(count++);
		setTimeout(() => {
			self.help.language = false;
		}, 20000);
		// console.log(count++);

		this.welcome();
	}

	public async loadConfig() {
		try {
			let location = "";
			console.log("$route:", this.$route);
			if (Array.isArray(this.$route.params.location)) {
				location = this.$route.params.location[0].toLowerCase();
			} else if (typeof this.$route.params.location === "string") {
				location = this.$route.params.location.toLowerCase();
			} else {
				location = window.location.pathname.substr(1).toLowerCase();
			}
			this.config = { ...config, ...(await axios.get(`/config/${window.location.hostname}/${location}`, { params: { language: this.currentLanguage } }).then(res => res.data)) };
			this.location = this.config.location;
		} catch (e) {
			// TODO: if the request fails due to something other than 404, show an error and retry
			console.error(e);
			console.log("Replacing with 404");
			this.$router.replace("/404");
		}
	}

	public async initBot() {
		Spin.show();
		window.localStorage.removeItem("blocks");
		try {
			const { data } = await axios.get(`${this.API}/chat/session/${this.assistantId}`);
			if (typeof data.session_id !== "undefined") {
				this.sessionId = data.session_id;
			} else {
				throw new Error(this.i18n("Server couldn't start a chat session for you. Please Please try again later."));
			}
			await this.ask("");
			this.inited = true;
		} catch (e) {
			console.error(e);
			this.modal({
				type: "error",
				title: this.i18n("Error"),
				content: this.i18n("Error with chat.") + " " + e
			});
		} finally {
			Spin.hide();
		}

		// Check if SpeechRecognition is supported by browser and choosed language
		if (this.SpeechRecognition !== false && Array.isArray(this.config?.speechLanguages) && this.config.speechLanguages.includes(this.currentLanguage)) {
			console.log("Speech recognition supported");

			this.dictation = new this.SpeechRecognition();
			this.dictation.lang = this.currentLanguage;
			this.dictation.interimResults = true;
			this.dictation.addEventListener("result", (event: any) => {
				console.log({ event });
				const text = Array.from(event.results)
					.map((result: any) => result[0])
					.map(result => result.transcript)
					.join("");
				this.question = text;
			});
			this.dictation.addEventListener("start", () => {
				this.dictating = true;
			});
			this.dictation.addEventListener("end", () => {
				if (this.question !== "") {
					console.log("we got it", this.question);
					this.dictation.stop();
				}
				console.log("end event");
				this.dictating = false;
				this.ask(this.question, undefined, true);
			});
		} else {
			if (this.SpeechRecognition === false) {
				console.warn("Speech recognition is not supported on your ''browser''");
			} else {
				if (!Array.isArray(this.config?.speechLanguages)) {
					console.warn("Speech recognition is not enabled");
				} else {
					console.warn(`Speech recognition is not supported for language code: ${this.currentLanguage}`);
				}
			}
		}
	}

	public welcome() {
		// TODO: Proper extention implementation - this code is temp
		if (typeof this.config.extensions !== "undefined" && this.config.extensions.includes("ChatAuth") && this.loggedin === false) {
			this.showGlass = true;
			this.login = true;
			return;
		}
		if (typeof this.config.welcome === "undefined" || this.config.welcome === "" || this.config.welcome === null) {
			console.log("We have no welcome message");
			this.initBot();
			this.showGlass = false;
			this.showWelcome = false;
		} else {
			this.showGlass = true;
			this.showWelcome = true;
		}
	}

	// nAudio component emits nAudioCreated event through Message component
	public addSpeechAudioToList(event: any) {
		this.speechAudios.push(event);
	}

	public playSpeechAudio(event: any) {
		try {
			// Stop all speech audios before playing selected audio
			this.speechAudios.forEach((audio: any) => {
				audio.pause();
			});
			// Play selected speech audio
			event.audio.play();
		} catch (error) {
			console.error(error);
		}
	}

	public updated() {
		if (typeof window.onscroll === "function") {
			// @ts-ignore
			window.onscroll();
		}
	}

	public close() {
		console.log("closing");
		// @ts-ignore
		Spin.show();
		this.conversation = [];
		this.sessionId = "";
		window.localStorage.removeItem("blocks");
		window.close();
		setTimeout(() => {
			Spin.hide();
			this.modal({
				type: "info",
				title: this.i18n("Closing"),
				content: this.i18n("This windows may not be closed automatically, please close your browser or app.")
			}).then(() => {
				window.location.reload();
			});
		}, 2000);
	}

	public askLanguage() {
		console.log("Opening language window...");
		this.currentLanguage = "";
	}

	public closeLanguageSelection() {
		this.showLanguages = false;
		if (this.inited === true) {
			this.showGlass = false;
		}
	}

	public async ask(text: string, suggestion?: any, userInput?: boolean, confirmSend = false, event: any = undefined) {
		console.log("ask(): Asking");
		this.askRef++;
		if (typeof event?.preventDefault === "function") {
			event.preventDefault();
		}
		// @ts-ignore
		if (userInput === true && text.trim() === "") {
			console.log("Ignoring empty user input");
			return false;
		}
		if (this.currentLanguage === "") {
			console.log("No lang selected, ignoring ask");
			return false;
		}

		this.showGlass = false;
		console.log("ask():", { userInput, le: this.censors.length, confirmSend });
		if (userInput && this.censors.length > 0) {
			// console.log("User input");

			const action = await this.censorAction(text);

			console.log("censorAction:", { action });

			if (action === "block") {
				console.warn("censorAction: block");
				this.modal({
					type: "warning",
					title: this.i18n("Question not sent"),
					content: this.i18n("Your question contains highly sensitive information and has not been sent.")
				});

				return false;
			} else if (action === "alert") {
				console.warn("censorAction: alert");
				await this.modal({
					type: "confirm",
					title: this.i18n("Are you sure?"),
					content: this.i18n("Your question contains sensitive information, do you still want to send it?"),
					cancelText: this.i18n("Cancel"),
					okText: this.i18n("Yes, send it")
				});
				return false;
			}
		}

		// console.log("Keeping");

		const location = this.location.replace("-dev", "");
		if (text !== "") {
			this.conversation.push({ type: "user", message: { input: text }, ref: this.askRef });
		}

		await this.sleep(200);

		this.typing = true;

		if (this.conversation.length > 1) {
			this.scrollDown();
			setTimeout(() => {
				this.scrollDown();
			}, 200);
		}

		this.question = "";
		const payload: any = {
			session_id: this.sessionId,
			language: this.currentLanguage,
			input: {
				message_type: "text",
				text
			},
			context: {
				version: this.version,
				semverMajor: this.semver?.major,
				semverMinor: this.semver?.minor,
				semverPatch: this.semver?.patch,
				site: this.location,
				location,
				language: this.currentLanguage,
				// @ts-ignore
				locationName: this.config.locationName,
				startBtn: this.startBtn
			}
		};

		if (text === "") {
			payload.init = true;
		}

		if (this.captchaToken !== "") {
			payload.captcha = this.captchaToken;
		}

		if (typeof suggestion === "object") {
			payload.input.suggestion_id = suggestion.value.input.suggestion_id;
			payload.input.message_type = undefined;
			payload.input.intents = suggestion.value.input.intents;
		}

		try {
			const { data } = await axios.post(`${this.API}/chat/message/${this.assistantId}`, payload);

			if (typeof data.translations === "object") {
				this.translationCache = Object.assign({}, this.translationCache, data.translations);
			}

			data.translations = undefined;
			console.log("Clearing session timeout");
			clearTimeout(this.sessionTimeout);
			this.sessionTimeout = setTimeout(() => {
				console.log("Timeout");
				this.timeoutAlert();
			}, 290000);

			if (typeof data.output === "object" && typeof data.output.generic === "object") {
				// Loop the data we got and push all the entries one by one with the correct types and doing pauses
				for (const message of data.output.generic) {
					if (message.response_type === "pause") {
						this.typing = message.typing;
						console.log("Pausing for", message.time);
						await this.sleep(message.time);
						this.scrollDown();
					} else {
						console.log("pushing");
						this.conversation.push({
							type: message.response_type,
							message
						});
					}
				}
				const ref: any = this.$refs[this.askRef];
				if (typeof ref === "object" && ref !== null && typeof ref.offsetTop === "number") {
					scrollTo(window.scrollX, ref.offsetTop - 25);
				}
			} else {
				this.conversation.push({ type: "unknown", message: {} });
			}
		} catch (e: any) {
			if (e.message.includes("403")) {
				this.captcha = true;
			} else {
				this.modal({
					type: "error",
					title: this.i18n("Error"),
					content: this.i18n("Error with chat.") + " " + e // TODO: better error messages here
				});
			}
		} finally {
			this.typing = false;
		}
	}

	public async startClick(context: string) {
		// Start chat
		this.startBtn = context;
		this.showGlass = false;
	}

	public i18n(input: string) {
		let output = "";
		if (typeof this.translationCache[input] !== "undefined") {
			output = this.translationCache[input];
		} else {
			// @ts-ignore
			if (typeof window.untranslated !== "object") {
				// @ts-ignore
				window.untranslated = [];
			}
			// @ts-ignore
			window.untranslated.push(input);
			output = input;
		}
		for (const regex of this.regexHack) {
			output = output.replace(regex[0], regex[1]);
		}
		return output;
	}

	public clickChild(e: Event) {
		e.preventDefault();
		if (e === null) {
			return;
		}
		// @ts-ignore
		if (typeof e.target === "object" && e.target.children.length > 0) {
			// @ts-ignore
			e.target.children[0].click();
		}
	}

	public async sleep(time: number) {
		return new Promise(resolve => setTimeout(resolve, time));
	}

	public async setCaptcha(response: string) {
		fetch(`${this.API}/chat/challenge`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify({
				response
			})
		})
			.then(res => res.json())
			.then(async data => {
				if (data.success) {
					this.captchaToken = data.jwt;
					this.captcha = false;
					if (this.conversation.length < 2) {
						this.ask("");
					}
				} else {
					throw this.i18n("Server couldn't verify.");
				}
			})
			.catch(e => {
				this.modal({
					type: "error",
					title: this.i18n("Error"),
					content: this.i18n("Failed to verify security challenge. Please try again later...") + " " + e
				});
			});
	}

	public censorAction(text: string): Promise<string> {
		return new Promise(resolve => {
			this.censors.forEach((censor, i) => {
				if (censor.regex.test(text)) {
					if (censor.action === "block") {
						this.question = this.question.replace(censor.regex, "(censored)");
					}
					return resolve(censor.action);
				}
			});
			return resolve("clean");
		});
	}

	public showRefreshUI(e: any) {
		this.registration = e.detail;
		// @ts-ignore
		Notice.info({
			title: " ",
			render: (h: any) => [
				h(
					"div",
					{
						class: "ivu-notice-title",
						style: "margin: -5px 0px 15px"
					},
					this.i18n("Update available") as string
				),
				h(
					"Button",
					{
						props: {
							type: "success",
							long: true,
							ghost: true
						},
						on: {
							click: this.refreshApp
						}
					},
					this.i18n("Update now") as string
				)
			]
		});
	}

	public async showFeedbackUI() {
		const session = true;

		const payload: any = {
			...this.feedback,
			location: this.location
		};

		if (session) {
			payload.sessionId = this.sessionId;
		}
		try {
			// await this.sendFeedback(payload);
		} catch (e) {
			this.modal({ title: this.i18n("Sorry, feedback was not sent") });
		}
	}

	public toggleDictation() {
		if (this.dictating) {
			this.dictation.stop();
			this.dictating = false;
		} else {
			this.dictation.start();
			this.dictating = true;
		}
	}

	public refreshApp() {
		if (!this.registration || !this.registration.waiting) {
			window.location.reload();
			return;
		}
		this.registration.waiting.postMessage("skipWaiting");
	}

	public scrollDown() {
		console.log("SCROLLING");
		window.scrollTo(window.scrollX, document.body.scrollHeight);
	}

	public mounted() {
		this.mountScroll();
		document.addEventListener("keydown", e => {
			if (this.showGlass && e.keyCode === 27) {
				this.showGlass = false;
			}
		});
	}

	public blockExpand(blockId: string, wasExpanded: boolean) {
		const blockTrack = JSON.parse(window.localStorage.getItem("blocks") as string) || [];
		const currentTime = Date.now();
		// Check user isn't mass clicking the block, set threshold to > 2 clicks in 2 second timeframe
		const index = blockTrack.findIndex((item: any) => item.blockId === blockId);
		if (index !== -1 && typeof blockTrack[index] === "object") {
			blockTrack[index].click = blockTrack[index].click + 1;
			if (currentTime - blockTrack[index].time <= 2000) {
				blockTrack[index].time = currentTime;
				if (blockTrack[index].click > 2) {
					// Stop tracking because the user is just clicking the block continously (Prevent dossing the backend and db)
					console.log("BT1");
					return;
				}
			} else {
				console.log("BT21.1");
				blockTrack[index].time = currentTime;
				blockTrack[index].click = 1;
			}
		} else {
			console.log("BT31.3");
			blockTrack.push({ blockId: blockId.toString(), time: currentTime, click: 1 });
		}
		window.localStorage.setItem("blocks", JSON.stringify(blockTrack));
		// Save block event to db, we need location, sessionId, blockId, expanded
		axios
			.post("/chat/block-track", {
				location: this.location,
				blockId: blockId.toString(),
				sessionId: this.sessionId,
				expanded: wasExpanded
			})
			.catch(e => {
				console.log(e);
			});
	}

	private async sendFeedback() {
		const payload = {
			location: this.location,
			feedback: this.feedback.feedback,
			rating: this.feedback.rating
		};

		if (this.feedback.rating === 0) {
			return (this.feedbackErrorNoRating = true);
		}

		this.feedbackErrorNoRating = false;
		if (this.feedback.sessionId === true) {
			// @ts-ignore
			payload.sessionId = this.sessionId;
		}

		try {
			const { data } = await axios.post("/chat/feedback", payload);
			console.log(data);
			this.feedbackUIVisible = false;

			// Empty feedback message
			this.feedback.feedback = "";
			this.feedback.rating = 0;
			this.feedback.sessionId = false;

			this.modal({
				type: "success",
				title: this.i18n("Success"),
				content: this.i18n(data.success)
			});
		} catch (error) {
			console.error(error);
			this.feedbackUIVisible = false;
			this.feedback.sessionId = false;
			this.feedback.rating = 0;
			this.modal({
				type: "error",
				title: this.i18n("Error"),
				content: this.i18n("Error saving feedback.")
			});
		}
	}

	private mountScroll() {
		window.onscroll = () => {
			this.scrolledToBottom = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight >= document.documentElement.offsetHeight - 90;
		};
	}

	public scrollTo(index: number) {
		console.log("Scrolling to index of", index);
		try {
			// @ts-ignore
			console.log(this.$refs.message[index]);
			// @ts-ignore
			this.$refs.message[index].$el.scrollIntoView({ behaviour: "smooth" });
		} catch (e) {
			console.warn(e);
			console.log(this.$refs.message);
		}
	}

	@Watch("showGlass")
	private onShowGlassChange(value: boolean, oldValue: boolean) {
		if (value === false && this.showWelcome) {
			this.showWelcome = false;
			this.initBot();
		}
	}

	@Watch("currentLanguage")
	private onPropertyChange(value: string, oldValue: string) {
		if (this.currentLanguage !== "") {
			if (this.inited === true) {
				console.log("changed language to", this.currentLanguage, "from", oldValue);
				this.conversation = [];
				this.translationCache = {};
				this.$router.replace({ query: { language: this.currentLanguage } });
				document.documentElement.lang = this.currentLanguage;
				this.initBot();
			}
			if (this.showWelcome) {
				this.loadConfig();
				this.showLanguages = false;
			}
		}
	}

	private async getLanguages() {
		try {
			const { data } = await axios.get("/chat/languages/");
			const languages: any = [];
			if (typeof this.config.languages.whitelist === "object") {
				data.filter((language: any) => {
					if (this.config.languages.whitelist.includes(language.code)) {
						languages.push(language);
					}
				});
				this.languages = languages;
			} else {
				this.languages = data;
			}
			// Top languages
			if (typeof this.config.languages.top === "object") {
				const topLanguages: any = [];
				this.config.languages.top.filter((langCode: any) => {
					topLanguages.push(this.languages.find(lang => lang.code === langCode));
				});
				if (topLanguages.length > 0) {
					this.topLanguages = topLanguages;
					// Languages without top languages
					this.languagesWithoutTopLanguages = this.languages.filter(language => !this.config.languages.top.includes(language.code));
				}
			}
		} catch (e) {
			this.modal({
				type: "error",
				title: this.i18n("Error"),
				content: this.i18n("Error loading languages. Please check your connection and try again.") + " " + e
			});
		}
	}

	private timeoutAlert() {
		if (!this.feedbackUIVisible) {
			this.modal({
				type: "warning",
				title: this.i18n("Session Timed Out"),
				content: this.i18n("Your session has timed out. Please refresh this page to start a new chat."),
				showCancel: false,
				okText: this.i18n("Refresh Chat")
			}).then(() => {
				window.location.reload();
			});
		} else {
			console.log("Timeout warning ingores as feedback is open.");
		}
	}

	private modal(options: { title?: string; content?: string; type?: "info" | "warning" | "error" | "success" | "confirm"; cancelText?: string; okText?: string; showCancel?: boolean; showOk?: boolean }) {
		this.modalCounter++;
		return new Promise((resolve, reject) => {
			console.log("Inline modal started");
			console.log({ options });
			const modalInstance = options as any;
			const id = this.modalCounter;
			modalInstance.id = id;
			modalInstance.resolve = (ok: any) => {
				this.modals.delete(id);
				resolve(ok);
			};
			modalInstance.reject = (error: any) => {
				this.modals.delete(id);
				reject(error);
			};
			this.modals.set(id, modalInstance);
			console.log(this.modals);
		});
	}

	// TODO: Do proper auth with backend using extention interface
	private chatAuth() {
		// Hash password
		// console.log(this.password);
		// console.log(this.location);
		this.authMessage = "";
		const pass = crypto.SHA256(this.password + this.location).toString();
		// console.log(pass);
		// console.log(this.config.meta.rnPass);

		if (pass === this.config.meta?.rnPass) {
			this.login = false;
			this.loggedin = true;
			this.password = "";
			this.welcome();
		} else {
			this.authMessage = "Password incorrect";
		}
	}
}
