
import { Options, Vue } from "vue-class-component";

@Options({
	components: {},
	props: {
		type: String,
		message: String,
		divide: Boolean
	}
})
export default class Message extends Vue {
	public type!: "image" | "text" | "option" | "suggestion" | "user";
	public message: any;
	public open: boolean = false;
	public divide: boolean = false;
	public custom: boolean = false;
}
