
import { Options, Vue } from "vue-class-component";

@Options({
	components: {},
	props: {
		test: String
	}
})
export default class Message extends Vue {
	public message: any;
}
