
// TODO: REMOVE @ts-nocheck
// @ts-nocheck
import { defineComponent } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPlayCircle, faPauseCircle, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
library.add(faPlayCircle, faPauseCircle, faExclamationCircle);

export default defineComponent({
	name: "NAudio",
	components: {
		FontAwesomeIcon
	},

	props: {
		src: String,
		theme: String
	},

	emits: ["nAudioCreated", "nAudioState"],

	data() {
		return {
			timer: undefined,
			duration: 0,
			percent: 0,
			isPlaying: false,
			error: false,
			circumference: 15 * 2 * Math.PI,
			lightTheme: false
		};
	},

	watch: {
		theme(themeVal) {
			this.lightTheme = themeVal === "light" ? true : false;
		}
	},
	mounted() {
		if (this.theme === "light") {
			this.lightTheme = true;
		}

		if (typeof this.src === "string") {
			// When audio is playable emit event
			this.$refs.audioPlayer.addEventListener("canplay", (event: any) => {
				this.$emit("nAudioCreated", this.$refs.audioPlayer);
			});

			// When audio starts playing
			this.$refs.audioPlayer.addEventListener("playing", (event: any) => {
				// console.log("playing");
				// Get audio duration
				this.duration = event.target.duration;
				this.isPlaying = true;
				this.checkProgress(this.duration, event.target);
			});

			// When audio is paused
			this.$refs.audioPlayer.addEventListener("pause", () => {
				// console.log("pause");
				clearTimeout(this.timer);
				this.isPlaying = false;
			});

			// When audio comes to end
			this.$refs.audioPlayer.addEventListener("ended", () => {
				// console.log("ended");
				clearTimeout(this.timer);
				this.isPlaying = false;
				this.percent = 0;
			});
		} else {
			console.error("nAudio: src is not string.");
		}
	},

	methods: {
		togglePlay() {
			if (this.isPlaying) {
				this.$refs.audioPlayer.pause();
			} else {
				// Check if errors to play audio
				if (this.$refs.audioPlayer.error === null) {
					// console.log("PLAY AUDIO");
					this.isPlaying = !this.isPlaying;
					this.$refs.audioPlayer.play();
					this.$emit("nAudioState", { audio: this.$refs.audioPlayer, play: true });
					return;
				}
				this.error = true;
				console.error(`${this.$refs.audioPlayer.error.message} (${this.$refs.audioPlayer.currentSrc})`);
			}
		},

		checkProgress(duration: any, element: any) {
			const increment = 10 / duration;
			this.percent = Math.min(increment * element.currentTime * 10, 100);
			this.startTimer(duration, element);
		},

		startTimer(duration: number, element: any) {
			if (this.percent < 100) {
				this.timer = setTimeout(() => {
					this.checkProgress(duration, element);
				}, 100);
			}
		},

		audioError() {
			this.error = true;
		}
	}
});
