
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import nButton from "./nButton.vue";
import nAudio from "@/components/nAudio.vue";
import { chatArrowIcon } from "@/icons";
import orb from "./orb.vue";

@Options({
	name: "Message",
	components: {
		nButton,
		nAudio,
		chatArrowIcon,
		orb
	},
	props: {
		type: String,
		message: Object,
		ref: Number,
		expandevent: Function
	},
	emits: ["nAudioCreated", "nAudioState"]
})
export default class Message extends Vue {
	public type!: "image" | "text" | "option" | "suggestion" | "user";
	public message: any;
	public ref?: number;
	public expandevent?: any;

	private messageState: any;

	@Watch("message.blocks", { deep: true })
	private onBlockChange(newBlocks: any) {
		const messageState = JSON.parse(this.messageState);
		let index = -1;

		for (let i = 0; i < newBlocks.length; i++) {
			// We are only interested in expansion/collapse of the block
			if (newBlocks[i].expanded !== messageState.blocks[i].expanded) {
				index = i;
				break;
			}
		}

		if (typeof this.expandevent === "undefined" || index === -1) {
			// No event has been defined
			return;
		}
		// Save new state to compare with
		this.messageState = JSON.stringify(this.message);
		// Call custom event on if expanded = true, we do not need to log collapsing
		this.expandevent(this.message.blocks[index].blockId, newBlocks[index].expanded);
	}

	public created() {
		this.messageState = JSON.stringify(this.message);
	}
}
