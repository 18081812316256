<template>
	<!-- This example requires Tailwind CSS v2.0+ -->
	<div class="modal-wrapper fixed z-50 inset-0 overflow-y-auto">
		<div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
			<!--
      Background overlay, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    -->
			<div class="fixed inset-0 transition-opacity" aria-hidden="true">
				<div class="absolute inset-0 bg-gray-500 opacity-75"></div>
			</div>

			<!-- This element is to trick the browser into centering the modal contents. -->
			<span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
			<!--
      Modal panel, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        To: "opacity-100 translate-y-0 sm:scale-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100 translate-y-0 sm:scale-100"
        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    -->
			<transition enter-active-class="ease-out duration-300" leave-active-class="ease-in duration-200" enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to-class="opacity-100 translate-y-0 sm:scale-100" leave-class="opacity-100 translate-y-0 sm:scale-100" leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
				<!-- Start of the modal -->
				<div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
					<div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
						<div v-if="!custom" class="sm:flex sm:items-start">
							<div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10" :class="{ 'bg-red-100': type === 'error', 'bg-yellow-100': type === 'warning', 'bg-green-100': type === 'success', 'bg-blue-100': type === 'info', 'bg-purple-100': type === 'confirm', 'hidden': typeof type === 'undefined' || type == '' }">
								<FontAwesomeIcon :icon="icon" :class="{ 'text-red-600': type === 'error', 'text-yellow-600': type === 'warning', 'text-green-600': type === 'success', 'text-blue-600': type === 'info', 'text-purple-600': type === 'confirm' }" aria-hidden="true" />
							</div>
							<div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
								<h3 id="modal-headline" class="text-lg leading-6 font-medium text-gray-900"> <slot name="title"></slot> </h3>
								<div class="mt-2">
									<p class="text-sm text-gray-500"> <slot></slot> </p>
								</div>
							</div>
						</div>
						<div v-else>
							<slot></slot>
						</div>
					</div>
					<div v-if="showOk || showCancel" class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:justify-end">
						<button v-if="showCancel" :aria-description="cancelText" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm" @click="$emit('reject')"> {{ cancelText }} </button>
						<button v-if="showOk" ref="modalOkBTN" :disabled="modalSubmitDisabled" :aria-description="okText" type="button" :class="{ 'opacity-50 cursor-not-allowed': modalSubmitDisabled }" class="mt-2 sm:mt-0 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-nBlue text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm" @click="modalSubmit()"> {{ okText }} </button>
					</div>
				</div>
			</transition>
		</div>
	</div>
</template>
<script lang="ts">
import { Options, Vue, prop } from "vue-class-component";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faExclamationCircle, faExclamationTriangle, faCheck, faInfoCircle, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

class Props {
	public type?: "error" | "warning" | "success" | "info" | "confirm";
	public showOk = prop<boolean>({ default: true });
	public showCancel = prop<boolean>({ default: false });
	public okText = prop<string>({ default: "Ok" });
	public cancelText = prop<string>({ default: "Cancel" });
	public custom = prop<boolean>({ default: false });
}
@Options({
	name: "nModal",
	components: {
		FontAwesomeIcon
	},
	emits: ["resolve", "reject"]
})
export default class nModal extends Vue.with(Props) {
	private get icon() {
		if (this.type === "error") {
			return faExclamationCircle;
		} else if (this.type === "warning") {
			return faExclamationTriangle;
		} else if (this.type === "success") {
			return faCheck;
		} else if (this.type === "info") {
			return faInfoCircle;
		} else if (this.type === "confirm") {
			return faQuestionCircle;
		} else {
			return "";
		}
	}

	private modalSubmitDisabled = false;

	mounted() {
		// Set ok button to focus, on alert modal open
		if (this.showOk && !this.showCancel) {
			// @ts-ignore
			this.$refs.modalOkBTN.focus();
		}
	}

	private async modalSubmit() {
		if (this.showOk && this.showCancel) {
			// Disable modal submit button for 1 second (for example to prevent submit same feedback more than once)
			this.modalSubmitDisabled = true;
			this.$emit("resolve");
			return setTimeout(() => {
				this.modalSubmitDisabled = false;
			}, 1000);
		}
		this.$emit("resolve");
	}
}
</script>
