<template>
	<div id="hcap-script" />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { loadApiEndpointIfNotAlready } from "@/scripts/hcaptcha-script";

@Options({
	props: {
		sitekey: {
			type: String,
			required: true
		},
		theme: {
			type: String,
			default: undefined
		},
		size: {
			type: String,
			default: undefined
		},
		tabindex: {
			type: String,
			default: undefined
		},
		language: {
			type: String,
			default: undefined
		},
		reCaptchaCompat: {
			type: Boolean,
			default: true
		},
		challengeContainer: {
			type: String,
			default: undefined
		},
		rqdata: {
			type: String,
			default: undefined
		},
		sentry: {
			type: Boolean,
			default: true
		},
		apiEndpoint: {
			type: String,
			default: "https://hcaptcha.com/1/api.js"
		},
		endpoint: {
			type: String,
			default: undefined
		},
		reportapi: {
			type: String,
			default: undefined
		},
		assethost: {
			type: String,
			default: undefined
		},
		imghost: {
			type: String,
			default: undefined
		}
	},
	emits: ["error"]
})
export default class Hcaptcha extends Vue {
	public widgetId: any = null;
	public hcaptcha: any = null;
	private sitekey!: string;
	private theme?: string;
	private size?: string;
	private tabindex?: string;
	private language?: string;
	private reCaptchaCompat!: boolean;
	private challengeContainer?: string;
	private rqdata?: string;
	private sentry!: boolean;
	private apiEndpoint!: string;
	private endpoint?: string;
	private reportapi?: string;
	private assethost?: string;
	private imghost?: string;

	public mounted() {
		return loadApiEndpointIfNotAlready(this.$props).then(this.onApiLoaded).catch(this.onError) as Promise<void>;
	}

	public unmounted() {
		try {
			if (this.widgetId) {
				if (typeof this.hcaptcha !== "undefined") {
					this.hcaptcha.reset(this.widgetId);
					this.hcaptcha.remove(this.widgetId);
				}
			}
		} catch (e: any) {
			console.error(e);
		}
	}

	private onApiLoaded() {
		this.hcaptcha = (window as any).hcaptcha;
		const opt: any = {
			"sitekey": this.sitekey,
			"theme": this.theme,
			"size": this.size,
			"tabindex": this.tabindex,
			"callback": this.onVerify,
			"expired-callback": this.onExpired,
			"chalexpired-callback": this.onChallengeExpired,
			"error-callback": this.onError,
			"open-callback": this.onOpen,
			"close-callback": this.onClose
		};
		if (this.challengeContainer) {
			opt["challenge-container"] = this.challengeContainer;
		}
		this.widgetId = this.hcaptcha.render(this.$el, opt);
		if (this.rqdata) {
			this.hcaptcha.setData(this.widgetId, { rqdata: this.rqdata });
		}
		this.onRendered();
	}

	private execute() {
		if (this.widgetId) {
			this.hcaptcha.execute(this.widgetId);
			this.onExecuted();
		}
	}

	private reset() {
		if (this.widgetId) {
			this.hcaptcha.reset(this.widgetId);
			this.onReset();
		} else {
			this.$emit("error", "Element is not rendered yet and thus cannot reset it. Wait for `rendered` event to safely call reset.");
		}
	}

	private onRendered() {
		this.$emit("rendered");
		this.execute();
	}

	private onExecuted() {
		this.$emit("executed");
	}

	private onReset() {
		this.$emit("reset");
	}

	private onError(e: any) {
		this.$emit("error", e);
		this.reset();
	}

	private onVerify() {
		const token = this.hcaptcha.getResponse(this.widgetId);
		const eKey = this.hcaptcha.getRespKey(this.widgetId);
		this.$emit("verify", token, eKey);
	}

	private onExpired() {
		this.$emit("expired");
	}

	private onChallengeExpired() {
		this.$emit("challengeExpired");
	}

	private onOpen() {
		this.$emit("opened");
	}

	private onClose() {
		this.$emit("closed");
	}
}
</script>
