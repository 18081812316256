
import { Options, Vue, prop } from "vue-class-component";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faExclamationCircle, faExclamationTriangle, faCheck, faInfoCircle, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

class Props {
	public type?: "error" | "warning" | "success" | "info" | "confirm";
	public showOk = prop<boolean>({ default: true });
	public showCancel = prop<boolean>({ default: false });
	public okText = prop<string>({ default: "Ok" });
	public cancelText = prop<string>({ default: "Cancel" });
	public custom = prop<boolean>({ default: false });
}
@Options({
	name: "nModal",
	components: {
		FontAwesomeIcon
	},
	emits: ["resolve", "reject"]
})
export default class nModal extends Vue.with(Props) {
	private get icon() {
		if (this.type === "error") {
			return faExclamationCircle;
		} else if (this.type === "warning") {
			return faExclamationTriangle;
		} else if (this.type === "success") {
			return faCheck;
		} else if (this.type === "info") {
			return faInfoCircle;
		} else if (this.type === "confirm") {
			return faQuestionCircle;
		} else {
			return "";
		}
	}

	private modalSubmitDisabled = false;

	mounted() {
		// Set ok button to focus, on alert modal open
		if (this.showOk && !this.showCancel) {
			// @ts-ignore
			this.$refs.modalOkBTN.focus();
		}
	}

	private async modalSubmit() {
		if (this.showOk && this.showCancel) {
			// Disable modal submit button for 1 second (for example to prevent submit same feedback more than once)
			this.modalSubmitDisabled = true;
			this.$emit("resolve");
			return setTimeout(() => {
				this.modalSubmitDisabled = false;
			}, 1000);
		}
		this.$emit("resolve");
	}
}
