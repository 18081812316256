<template>
	<div class="audio-player h-10">
		<template v-if="src">
			<!-- {{ {duration, isPlaying, percent: `${Math.round(percent)}%` } }} -->
			<audio ref="audioPlayer" :src="src" preload="auto" @error="audioError()" />
			<!-- Circle progress bar -->
			<div class="inline-flex items-center justify-center overflow-hidden rounded-full">
				<svg class="w-10 h-10">
					<circle :class="{ 'text-gray-400': !error, 'text-red-500': error }" stroke-width="3" stroke="currentColor" fill="transparent" r="15" cx="20" cy="20" />
					<!-- Duration indication circle -->
					<circle class="text-nBlue" stroke-width="3" :stroke-dasharray="circumference" :stroke-dashoffset="circumference - (percent / 100) * circumference" stroke-linecap="round" stroke="currentColor" fill="transparent" r="15" cx="20" cy="20" />
				</svg>
				<span :class="{ 'text-black': !error && !lightTheme, ' text-white': !error && lightTheme, 'text-red-500': error && !lightTheme, 'text-red-400': error && lightTheme }" class="absolute play-button flex cursor-pointer" @click="togglePlay()">
					<FontAwesomeIcon v-if="!error && !isPlaying" class="w-6 h-6" :icon="['fas', 'play-circle']" />
					<FontAwesomeIcon v-if="!error && isPlaying" :icon="['fas', 'pause-circle']" />
					<FontAwesomeIcon v-if="error" class="w-6 h-6" :icon="['fas', 'exclamation-circle']" />
				</span>
			</div>
		</template>
	</div>
</template>
<script lang="ts">
// TODO: REMOVE @ts-nocheck
// @ts-nocheck
import { defineComponent } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPlayCircle, faPauseCircle, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
library.add(faPlayCircle, faPauseCircle, faExclamationCircle);

export default defineComponent({
	name: "NAudio",
	components: {
		FontAwesomeIcon
	},

	props: {
		src: String,
		theme: String
	},

	emits: ["nAudioCreated", "nAudioState"],

	data() {
		return {
			timer: undefined,
			duration: 0,
			percent: 0,
			isPlaying: false,
			error: false,
			circumference: 15 * 2 * Math.PI,
			lightTheme: false
		};
	},

	watch: {
		theme(themeVal) {
			this.lightTheme = themeVal === "light" ? true : false;
		}
	},
	mounted() {
		if (this.theme === "light") {
			this.lightTheme = true;
		}

		if (typeof this.src === "string") {
			// When audio is playable emit event
			this.$refs.audioPlayer.addEventListener("canplay", (event: any) => {
				this.$emit("nAudioCreated", this.$refs.audioPlayer);
			});

			// When audio starts playing
			this.$refs.audioPlayer.addEventListener("playing", (event: any) => {
				// console.log("playing");
				// Get audio duration
				this.duration = event.target.duration;
				this.isPlaying = true;
				this.checkProgress(this.duration, event.target);
			});

			// When audio is paused
			this.$refs.audioPlayer.addEventListener("pause", () => {
				// console.log("pause");
				clearTimeout(this.timer);
				this.isPlaying = false;
			});

			// When audio comes to end
			this.$refs.audioPlayer.addEventListener("ended", () => {
				// console.log("ended");
				clearTimeout(this.timer);
				this.isPlaying = false;
				this.percent = 0;
			});
		} else {
			console.error("nAudio: src is not string.");
		}
	},

	methods: {
		togglePlay() {
			if (this.isPlaying) {
				this.$refs.audioPlayer.pause();
			} else {
				// Check if errors to play audio
				if (this.$refs.audioPlayer.error === null) {
					// console.log("PLAY AUDIO");
					this.isPlaying = !this.isPlaying;
					this.$refs.audioPlayer.play();
					this.$emit("nAudioState", { audio: this.$refs.audioPlayer, play: true });
					return;
				}
				this.error = true;
				console.error(`${this.$refs.audioPlayer.error.message} (${this.$refs.audioPlayer.currentSrc})`);
			}
		},

		checkProgress(duration: any, element: any) {
			const increment = 10 / duration;
			this.percent = Math.min(increment * element.currentTime * 10, 100);
			this.startTimer(duration, element);
		},

		startTimer(duration: number, element: any) {
			if (this.percent < 100) {
				this.timer = setTimeout(() => {
					this.checkProgress(duration, element);
				}, 100);
			}
		},

		audioError() {
			this.error = true;
		}
	}
});
</script>

<style scoped>
/* Needed for safari to get same look as chrome */
.play-button svg {
	width: 24px;
	height: 24px;
}
</style>
