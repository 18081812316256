<template>
	<!--<textarea class="py-2 px-4 text-black font-semibold rounded-lg shadow-md focus:outline-none focus:ring-1 focus:ring-green-400 focus:ring-opacity-10"></textarea>-->
	<textarea :ref="inputRef" :value="modelValue" class="w-full h-44 border-gray-50 bg-gray-50 p-2 text-black sm:text-sm font-semibold rounded-lg focus:ring-1 focus:ring-nBlue focus:ring-opacity-100" @input="onChanged"></textarea>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
	name: "NTextarea",

	props: {
		modelValue: String,
		inputRef: String
	},

	emits: ["update:modelValue", "enter"],

	setup(props, { emit }) {
		const onChanged = (e: any) => emit("update:modelValue", e.currentTarget.value);

		return {
			onChanged
		};
	},
	mounted() {
		if (this.$refs?.feedbackMessage && typeof this.$refs?.feedbackMessage === "object") {
			// @ts-ignore
			this.$refs?.feedbackMessage.focus();
		}
	}
});
</script>
