<template>
	<div class="flex items-center">
		<input :id="id ? id : null" type="checkbox" class="h-4 w-4 text-nBlue focus:ring-nBlue border-gray-300 rounded" @keyup.enter="onEnter" @click="onClick" />
		<label v-if="hasSlot('default')" :for="id" class="ml-2 block text-sm text-gray-900">
			<slot></slot>
		</label>
	</div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
	name: "NCheckbox",

	props: {
		modelValue: Boolean,
		id: String
	},

	emits: ["update:modelValue"],

	setup(props, { emit, slots }) {
		const hasSlot = (name: string | number) => !!slots[name];
		const onClick = (e: any) => emit("update:modelValue", e.target.checked);

		const onEnter = (e: any) => {
			e.target.checked = !e.target.checked;
			emit("update:modelValue", e.target.checked);
		};

		return {
			onClick,
			onEnter,
			hasSlot
		};
	}
});
</script>
