<template>
	<div class="relative inline-block text-left">
		<div>
			<button v-if="custom" type="button" class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500" :aria-expanded="open" aria-haspopup="true" @click="open = !open">
				<slot name="content"></slot>
				<!-- Heroicon name: solid/chevron-down -->
				<svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
					<path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
				</svg>
			</button>
			<button v-else type="button" :aria-expanded="open" aria-haspopup="true" @click="open = !open">
				<slot name="content"></slot>
			</button>
		</div>

		<!--
    Dropdown menu, show/hide based on menu state.

    Entering: "transition ease-out duration-100"
      From: "transform opacity-0 scale-95"
      To: "transform opacity-100 scale-100"
    Leaving: "transition ease-in duration-75"
      From: "transform opacity-100 scale-100"
      To: "transform opacity-0 scale-95"
  -->
		<div :class="{ hidden: !open, 'divide-y divide-gray-100': divide }" class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
			<slot></slot>
		</div>
	</div>
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
	components: {},
	props: {
		type: String,
		message: String,
		divide: Boolean
	}
})
export default class Message extends Vue {
	public type!: "image" | "text" | "option" | "suggestion" | "user";
	public message: any;
	public open: boolean = false;
	public divide: boolean = false;
	public custom: boolean = false;
}
</script>
